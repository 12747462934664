import './_three-boxes.scss'

const threeBoxes = function () {

    const blocks = document.querySelectorAll('section.three-boxes')

    for (const block of blocks) {

        let boxes = block.querySelectorAll('.box');
        let images = block.querySelectorAll('img');

        boxes.forEach(box => {
            box.addEventListener('mouseover', () => {
                let boxIndex = box.dataset.index;
                images.forEach(image => {
                    let imageIndex = image.dataset.index;
                    if (boxIndex === imageIndex) {
                        image.classList.add('active');
                    }
                });
            });
            box.addEventListener('mouseleave', () => {
                images.forEach(image => {
                    image.classList.remove('active');
                });
            });
        });
    }
};

document.addEventListener('DOMContentLoaded', function () {
    threeBoxes()
}, false);

window.addEventListener('acf/loaded/three-boxes', function (e) {
    threeBoxes()
});